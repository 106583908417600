import { AxiosInstance, AxiosResponse } from 'axios';
import queryString, { UrlObject } from 'query-string';

import { OneSpanEvent } from '@breathelife/onespan-integration';
import {
  Attachment,
  AuditLogsRowData,
  DynamicPdfDataGetRequest,
  ESignCeremony,
  ESignCeremonyStatus,
  ESignSigner2FAInfo,
  LeadAccessTokenData,
  PageQueryOptions,
  Paginated,
  Settings,
  Theme,
  UserNotificationOptions,
  UserNotificationPreference,
  StoredFileDocType,
  StoredFile,
  StoredFileWithSignedUrl,
  ExpandableFileTemplateRule,
  ReplacedESignCeremony,
  ESignFieldSetting,
  FileTemplateOrigin,
  FileTemplate,
  FileTemplateRule,
  Conditions,
  PatchFileTemplateRuleData,
  ParticipantRole,
  Party,
  Participant,
  PdfDocumentType,
  Signer,
  DynamicPdfType,
  CreateRequest,
  ApplicationPointOfSaleDecisions,
  AgentLicenseProperties,
  ApplicationSupportApplication,
  ApplicationSupportApplicationOverview,
  ESignCeremonyPatchRequestData,
  TotalPremiums,
  JetDecisionOutcomes,
  FileTemplateRecipient,
  QuestionnaireBlueprintCopyableOption,
  InsuredUnderwritingOutcomesReports,
  ApplicationSupportESignatureCeremony,
  ApplicationSupportAssociatedFile,
  PaymentTransaction,
  AuditLog,
  ApplicationFilters,
  SortParams,
  Language,
  Questionnaire,
  FindQuestionnairesForProUserQuery,
  CreateApplicationPayload,
  QuestionnaireVersionPricingNodeIds,
  Notification,
  FetchQuotesRequest,
  ProductsEntity,
  QuoteInfo,
  StoredFileWithExistsProperty,
  LineOfBusinessName,
  Answers,
  ComprehensivePricingAPIReturnValue,
} from '@breathelife/types';

import { CryptoMaterial } from '../Models/cryptoMaterial';
import { AdminGateway } from './adminGateway';
import { ConsumerGateway } from './consumerGateway';
import { buildPageQueryOptions, convertObjectToQueryString } from './helpers';
import { addReleaseVersionHeader, addTimezoneIntoHeaderInterceptor, enforceReleaseVersion } from './interceptors';
import Urls from './urls';

export class Gateway {
  private instance: AxiosInstance;
  public admin: AdminGateway;
  public consumer: ConsumerGateway;

  constructor(releaseVersion: string, instance: AxiosInstance) {
    this.instance = instance;

    this.instance.interceptors.request.use(addReleaseVersionHeader(releaseVersion));
    this.instance.interceptors.request.use(addTimezoneIntoHeaderInterceptor());
    this.instance.interceptors.response.use((response) => response, enforceReleaseVersion);

    this.admin = new AdminGateway(this.instance);
    this.consumer = new ConsumerGateway(this.instance);
  }

  public async proCreateApplication<T>(data: any): Promise<AxiosResponse<T>> {
    return this.instance.post<T>(Urls.pro.applications, data);
  }

  public async proFetchApplication<T>(applicationId: string): Promise<AxiosResponse<T>> {
    const url = Urls.pro.applications;
    return this.instance.get(`${url}/${applicationId}`);
  }

  public async proUpdateApplication<T>(applicationId: string, data: any): Promise<AxiosResponse<T>> {
    const url = Urls.pro.applications;
    return this.instance.patch<T>(`${url}/${applicationId}`, data);
  }

  public async proCopyApplication<T>(data: {
    applicationId: string;
    copyableOptions: QuestionnaireBlueprintCopyableOption[];
  }): Promise<AxiosResponse<T>> {
    return this.instance.post<T>(Urls.pro.applicationCopies, data);
  }

  public async launchNewAssistedApplication<T>(data: CreateApplicationPayload): Promise<AxiosResponse<T>> {
    return this.instance.post<T>(Urls.pro.assistedApplicationLaunch, data);
  }

  public async launchAssistedApplication<T>(applicationId: string, data: any): Promise<AxiosResponse<T>> {
    const url = Urls.pro.assistedApplicationLaunch;
    return this.instance.patch<T>(`${url}/${applicationId}`, data);
  }

  public async updateAssistedApplicationAnswers<T>(applicationId: string, data: any): Promise<AxiosResponse<T>> {
    const url = Urls.pro.assistedApplicationAnswers;
    return this.instance.patch<T>(`${url}/${applicationId}`, data);
  }

  public async manuallySubmitApplication(applicationId: string): Promise<Buffer | null> {
    try {
      const response = await this.instance.post(
        Urls.admin.manualSubmissions,
        { applicationId },
        { responseType: 'arraybuffer' },
      );

      const buffer = Buffer.from(response.data);

      return buffer;
    } catch {
      return null;
    }
  }

  public async deleteAllOutcomesForParticipant<T>(data: {
    applicationId: string;
    participantId: string;
  }): Promise<AxiosResponse<T>> {
    const { applicationId, participantId } = data;
    const url = queryString.stringifyUrl({
      url: `${Urls.pro.applicationsOutcomes}/${participantId}`,
      query: { applicationId },
    });
    return this.instance.delete(url);
  }

  public async getApplicationAssignee(applicationId: string): Promise<AxiosResponse> {
    return this.instance.get(`${Urls.pro.applicationsAssignee}/${applicationId}`);
  }

  public async getAssigneeInfoFromToken(type: string, token: string): Promise<AxiosResponse> {
    return this.instance.get(`${Urls.pro.applicationsAssignee}?type=${type}&token=${token}`);
  }

  public async createLead<T>(data: any): Promise<AxiosResponse<T>> {
    return this.instance.post<T>(`${Urls.pro.leads}`, data);
  }

  public async sendIdentityVerificationRequest<T>(data: any): Promise<AxiosResponse<T>> {
    return this.instance.post<T>(`${Urls.pro.identityVerification}`, data);
  }

  public async fetchLeads<T>(queryString: string): Promise<AxiosResponse<T>> {
    return this.instance.get<T>(`${Urls.pro.leads}${queryString}`);
  }

  public async getLead<T>(leadId: number): Promise<AxiosResponse<T>> {
    return this.instance.get<T>(`${Urls.pro.leads}/${leadId}`);
  }

  public async updateLead<T>(leadId: number, data: any): Promise<AxiosResponse<T>> {
    return this.instance.patch<T>(`${Urls.pro.leads}/${leadId}`, data);
  }

  public async deleteLead<T>(leadId: number): Promise<AxiosResponse<T>> {
    return this.instance.delete<T>(`${Urls.pro.leads}/${leadId}`);
  }

  public async archiveLead<T>(leadId: number): Promise<AxiosResponse<T>> {
    return this.instance.put<T>(`${Urls.pro.leads}/archives/${leadId}`, {});
  }

  public async unarchiveLead<T>(leadId: number): Promise<AxiosResponse<T>> {
    return this.instance.delete<T>(`${Urls.pro.leads}/archives/${leadId}`);
  }

  public async validateLeadEmail(leadId: number): Promise<AxiosResponse<{ isValid: boolean; verdict?: string }>> {
    return this.instance.get<{ isValid: boolean; verdict?: string }>(`${Urls.pro.leads}/email-validation/${leadId}`);
  }

  public async sendInvitationEmailToLead<T>(leadId: number): Promise<AxiosResponse<T>> {
    return this.instance.post<T>(`${Urls.pro.leads}/invitation`, { leadId });
  }

  public async updateLeadNote<T>(leadId: number, note: string): Promise<AxiosResponse<T>> {
    return this.instance.post<T>(`${Urls.pro.leads}/notes`, { leadId, note });
  }

  public async updateLeadCommunication(appId: string, data: any): Promise<AxiosResponse> {
    return this.instance.post(`${Urls.pro.leadsCommunication}`, { appId, ...data });
  }

  public async getLeadCommunication(applicationId: string): Promise<AxiosResponse> {
    return this.instance.get(`${Urls.pro.leadsCommunication}/${applicationId}`);
  }

  public async generatePrivateLinkToken(leadId: number): Promise<AxiosResponse<LeadAccessTokenData>> {
    return this.instance.post<LeadAccessTokenData>(`${Urls.pro.accessToken}`, {
      leadId,
      type: 'private',
    });
  }

  public async getPublicAccessLink(): Promise<AxiosResponse<LeadAccessTokenData>> {
    const url = queryString.stringifyUrl({ url: Urls.pro.accessToken, query: { type: 'public' } });
    return this.instance.get<LeadAccessTokenData>(url);
  }

  public async getCoBrowsingAccessLink(appId: string): Promise<AxiosResponse<LeadAccessTokenData>> {
    const url = queryString.stringifyUrl({ url: Urls.pro.accessToken, query: { type: 'co-browsing', appId } });
    return this.instance.get<LeadAccessTokenData>(url);
  }

  public async assignLeads<T>(leadIds: number[], userId: string): Promise<AxiosResponse<T>> {
    return this.instance.post<T>(`${Urls.pro.leadsAssign}`, {
      leadIds,
      userId,
    });
  }

  public async updateUserNotificationPreferences(
    userId: string,
    data: UserNotificationOptions,
  ): Promise<AxiosResponse<UserNotificationPreference>> {
    return this.instance.patch<UserNotificationPreference>(`${Urls.pro.userNotificationPreferences}/${userId}`, data);
  }

  public async fetchProducts(applicationId: string, lang?: string): Promise<AxiosResponse> {
    const url = queryString.stringifyUrl({ url: Urls.shared.products, query: { applicationId, lang } });
    return this.instance.get(url);
  }

  public async fetchQuotes(data: FetchQuotesRequest): Promise<AxiosResponse<QuoteInfo>> {
    return this.instance.post(`${Urls.shared.quotes}`, data);
  }

  public async fetchSummary(
    applicationId: string,
    options?: { sectionId?: string; lang?: string },
  ): Promise<AxiosResponse> {
    const query = options ? queryString.stringify(options) : {};
    const url = `${Urls.shared.summary}/${applicationId}?${query}`;

    return this.instance.get(url);
  }

  public async getCarrierQuestionnaire<T = any>(applicationId: string): Promise<AxiosResponse<T>> {
    return this.instance.get(`${Urls.shared.carrierQuestionnaires}/${applicationId}`);
  }

  public async submitNeedsAnalysis(
    appId: string,
    coverageAmount: number,
    product: string,
    premium: number,
  ): Promise<AxiosResponse> {
    return this.instance.post(`${Urls.shared.submission}`, { appId, coverageAmount, product, premium });
  }

  public async submitInsuranceApplication(
    applicationId: string,
    premium?: number | null,
    submissionSource?: string,
  ): Promise<AxiosResponse> {
    return this.instance.post(`${Urls.shared.applications}/submission`, {
      appId: applicationId,
      premium,
      submissionSource,
    });
  }

  public async finishSubmissionInsuranceApplication(applicationId: string): Promise<AxiosResponse> {
    return this.instance.patch(`${Urls.shared.applications}/submission/${applicationId}`, { pdfInfos: [] });
  }

  public async signApplicationUsingCrypto(
    applicationId: string,
    cryptoMaterial: CryptoMaterial,
  ): Promise<AxiosResponse> {
    return this.instance.put(`${Urls.shared.cryptoSignature}?appId=${applicationId}`, {
      pubkey: cryptoMaterial.pubkey,
      signedDigest: cryptoMaterial.signature,
      domain: location.origin,
    });
  }

  public async fetchCryptoSignatureInformation(applicationId: string): Promise<AxiosResponse> {
    return this.instance.get(`${Urls.shared.cryptoSignature}/${applicationId}`);
  }

  public async fetchApplicationPdf(
    applicationId: string,
    params?: { polling?: string; lang?: string },
  ): Promise<AxiosResponse> {
    const query = params ? queryString.stringify(params) : {};
    const url = `${Urls.shared.pdf}/${applicationId}?${query}`;

    return this.instance.get(url);
  }

  public async fetchThemes(): Promise<AxiosResponse<Theme[]>> {
    return this.instance.get(Urls.shared.theme, {
      // Remove auth header for current request only
      transformRequest: (data, headers: any) => {
        delete headers?.['Authorization'];
        delete headers?.common?.['Authorization'];
        return data;
      },
    });
  }

  public async fetchThemeForMga(mgaId: string): Promise<AxiosResponse<Theme>> {
    const url = queryString.stringifyUrl({ url: Urls.shared.theme, query: { mgaId } });
    const response = await this.instance.get(url, {
      // Remove auth header for current request only
      transformRequest: (data, headers: any) => {
        delete headers?.['Authorization'];
        delete headers?.common?.['Authorization'];
        return data;
      },
    });
    return response;
  }

  public async fetchDefaultTheme(): Promise<AxiosResponse<Theme>> {
    const url = queryString.stringifyUrl({ url: Urls.shared.theme, query: { defaultTheme: true } });
    return this.instance.get(url, {
      // Remove auth header for current request only
      transformRequest: (data, headers: any) => {
        delete headers?.['Authorization'];
        delete headers?.common?.['Authorization'];
        return data;
      },
    });
  }

  public async updateTheme(themeId: string, updatedTheme: Partial<Theme>): Promise<AxiosResponse<Theme>> {
    return this.instance.put(`${Urls.shared.theme}/${themeId}`, updatedTheme);
  }

  public async fetchPublicSettings(): Promise<AxiosResponse<Settings>> {
    return this.instance.get(Urls.shared.settings, {
      // Remove auth header for current request only
      transformRequest: (data, headers: any) => {
        delete headers?.['Authorization'];
        delete headers?.common?.['Authorization'];
        return data;
      },
    });
  }

  public async getAddons(applicationId: string): Promise<AxiosResponse> {
    return this.instance.get(`${Urls.shared.addons}/?appId=${applicationId}`);
  }

  public async getParticipant(participantId: string): Promise<AxiosResponse<Participant>> {
    return this.instance.get(`${Urls.shared.participants}/${participantId}`);
  }

  public async getParticipants(params: {
    applicationId?: string;
    partyId?: string;
    roleType?: string;
  }): Promise<AxiosResponse<Participant<Party.Flattened>[]>> {
    const url = queryString.stringifyUrl({ url: Urls.shared.participants, query: params });
    return this.instance.get(url);
  }

  public async createParticipant(data: {
    applicationId: string;
    partyId: string;
    roleId: string;
  }): Promise<AxiosResponse<Participant>> {
    return this.instance.post(Urls.shared.participants, data);
  }

  public async updateParticipant(participantId: string, data: { roleId: string }): Promise<AxiosResponse<Participant>> {
    return this.instance.patch(`${Urls.shared.participants}/${participantId}`, data);
  }

  public async deleteParticipant(participantId: string): Promise<AxiosResponse<Participant>> {
    return this.instance.delete(`${Urls.shared.participants}/${participantId}`);
  }

  public async getParty(partyId: string): Promise<AxiosResponse<Party.Flattened>> {
    return this.instance.get(`${Urls.shared.parties}/${partyId}`);
  }

  public async fetchParties(): Promise<AxiosResponse<Party.Flattened[]>> {
    return this.instance.get(Urls.shared.parties);
  }

  public async createParties(
    data: Omit<Party.Party, 'id' | 'createdAt' | 'updatedAt'>[],
  ): Promise<AxiosResponse<Party.Flattened[]>> {
    return this.instance.post(Urls.shared.parties, data);
  }

  public async updateParty(
    partyId: string,
    data: Partial<Omit<Party.Flattened, 'id' | 'type' | 'createdAt' | 'updatedAt'>>,
  ): Promise<AxiosResponse<Party.Flattened>> {
    return this.instance.put(`${Urls.shared.parties}/${partyId}`, data);
  }

  public async deleteParty(partyId: string): Promise<AxiosResponse<Party.Flattened>> {
    return this.instance.delete(`${Urls.shared.parties}/${partyId}`);
  }

  public async fetchParticipantRoles(): Promise<AxiosResponse<ParticipantRole[]>> {
    return this.instance.get(`${Urls.shared.participantRoles}`);
  }

  public async getParticipantRole(roleId: string): Promise<AxiosResponse<ParticipantRole>> {
    return this.instance.get(`${Urls.shared.participantRoles}/${roleId}`);
  }

  public async sendESignatureRequest(
    applicationId: string,
    signers: ESignSigner2FAInfo[],
  ): Promise<AxiosResponse<ESignCeremony>> {
    return this.instance.patch(`${Urls.shared.eSignCeremonies}/${applicationId}`, {
      status: ESignCeremonyStatus.IN_PROGRESS,
      signers,
    });
  }

  public async getESignCeremony(applicationId: string): Promise<AxiosResponse<ESignCeremony | null>> {
    const url = queryString.stringifyUrl({
      url: `${Urls.shared.eSignCeremonies}/${applicationId}`,
      query: { withSigners: true },
    });

    return this.instance.get(url);
  }

  public async updateESignCeremony(
    applicationId: string,
    data: ESignCeremonyPatchRequestData,
  ): Promise<AxiosResponse<ESignCeremony>> {
    return this.instance.patch(`${Urls.shared.eSignCeremonies}/${applicationId}`, data);
  }

  public async cancelESignCeremony(applicationId: string): Promise<AxiosResponse<ReplacedESignCeremony | null>> {
    return this.instance.patch(`${Urls.shared.eSignCeremonies}/${applicationId}`, {
      status: ESignCeremonyStatus.CANCELLED,
    });
  }

  public async handleOnespanEventCallback(event: OneSpanEvent, baseURL: string): Promise<AxiosResponse> {
    return this.instance.post(Urls.shared.onespanEventCallback, event, { baseURL });
  }

  public async getFileTemplateRecipient(id: string): Promise<AxiosResponse<FileTemplateRecipient>> {
    return this.instance.get(`${Urls.shared.fileTemplateRecipients}/${id}`);
  }

  public async findFileTemplateRecipients(params: {
    fileTemplateId: string;
  }): Promise<AxiosResponse<FileTemplateRecipient[]>> {
    const { fileTemplateId } = params;
    const url = queryString.stringifyUrl({ url: Urls.shared.fileTemplateRecipients, query: { fileTemplateId } });
    return this.instance.get(url);
  }

  public async updateFileTemplateRecipient(
    id: string,
    data: Partial<Omit<FileTemplateRecipient, 'id' | 'fileTemplateId'>>,
  ): Promise<AxiosResponse<FileTemplateRecipient>> {
    return this.instance.patch(`${Urls.shared.fileTemplateRecipients}/${id}`, data);
  }

  public async createFileTemplateRecipient(
    data: Omit<FileTemplateRecipient, 'id' | 'createdAt' | 'participantRole'>,
  ): Promise<AxiosResponse<FileTemplateRecipient>> {
    return this.instance.post(Urls.shared.fileTemplateRecipients, data);
  }

  public async deleteFileTemplateRecipient(id: string): Promise<AxiosResponse<FileTemplateRecipient>> {
    return this.instance.delete(`${Urls.shared.fileTemplateRecipients}/${id}`);
  }

  public async updateESignFieldSetting(
    id: string,
    data: Partial<Omit<ESignFieldSetting, 'id' | 'signerSettingId'>>,
  ): Promise<AxiosResponse<ESignFieldSetting>> {
    return this.instance.patch(`${Urls.shared.eSignFieldSettings}/${id}`, data);
  }

  public async createESignFieldSetting(
    data: Partial<CreateRequest<ESignFieldSetting>>,
  ): Promise<AxiosResponse<ESignFieldSetting>> {
    return this.instance.post(Urls.shared.eSignFieldSettings, data);
  }

  public async deleteESignFieldSetting(id: string): Promise<AxiosResponse<ESignFieldSetting>> {
    return this.instance.delete(`${Urls.shared.eSignFieldSettings}/${id}`);
  }

  public async submitApplicationForExternalSignature(applicationId: string): Promise<AxiosResponse> {
    return this.instance.post(Urls.shared.externalSignature, {
      applicationId,
      domain: location.origin,
    });
  }

  public async fetchAuditLogs(
    query: { resourceName?: string; resourceId?: string; actionType?: string },
    options: PageQueryOptions<AuditLogsRowData>,
  ): Promise<AxiosResponse<Paginated<AuditLog>>> {
    const pagination = options ? buildPageQueryOptions(options) : {};
    const queryString = convertObjectToQueryString({ ...query, ...pagination });
    const url = Urls.admin.auditLogs;

    return this.instance.get(`${url}/${queryString}`);
  }

  public async upsertApplicationWithDebugToolbar<T>(
    leadId: number,
    applicationId: string | undefined,
    data: { answers: Record<string, any> },
  ): Promise<AxiosResponse<T>> {
    const url = Urls.shared.debugToolbarApplications;
    const payload = { ...data, leadId, applicationId };
    return this.instance.post(url, payload);
  }

  public async updateApplicationWithDebugToolbar<T>(
    applicationId: string,
    data: { subsectionId: string },
  ): Promise<AxiosResponse<T>> {
    const url = Urls.shared.debugToolbarApplications;
    return this.instance.put(`${url}/${applicationId}`, data);
  }

  public async createLeadWithDebugToolbar<T>(data: any): Promise<AxiosResponse<T>> {
    const url = Urls.shared.debugToolbarLeads;
    return this.instance.post(url, data);
  }

  public async createApplicationAttachment(data: {
    applicationId: string;
    file: File;
  }): Promise<AxiosResponse<Attachment>> {
    const formData = new FormData();
    formData.append('applicationId', data.applicationId);
    formData.append('file', data.file);

    const url = Urls.pro.applicationsAttachments;
    return this.instance.post(url, formData);
  }

  public async createApplicationFile(data: {
    applicationId: string;
    templateId?: string;
    file: File;
    docType: StoredFileDocType;
  }): Promise<AxiosResponse<StoredFile>> {
    const formData = new FormData();
    formData.append('applicationId', data.applicationId);
    formData.append('file', data.file);
    formData.append('docType', data.docType);

    if (data.templateId) {
      formData.append('templateId', data.templateId);
    }

    const url = Urls.pro.applicationFiles;
    return this.instance.post(url, formData);
  }

  /**
   * @deprecated - use {@link fetchFilesForApplication}
   */
  public fetchAttachmentsForApplication(applicationId: string): Promise<AxiosResponse<Attachment[]>> {
    const url = queryString.stringifyUrl({ url: Urls.pro.applicationsAttachments, query: { applicationId } });
    return this.instance.get(url);
  }

  /**
   * @deprecated - use {@link getApplicationFileWithSignedUrl}
   */
  public getApplicationAttachmentRedirectUrl(
    attachmentId: string,
    download: boolean,
  ): Promise<AxiosResponse<{ redirectUrl: string }>> {
    const url = queryString.stringifyUrl({
      url: `${Urls.pro.applicationsAttachments}/${attachmentId}`,
      query: { download },
    });
    return this.instance.get(url);
  }

  /**
   * @deprecated - use {@link deleteApplicationFile}
   */
  public deleteApplicationAttachment(attachmentId: string): Promise<AxiosResponse<Attachment>> {
    return this.instance.delete(`${Urls.pro.applicationsAttachments}/${attachmentId}`);
  }

  public fetchFilesForApplication(applicationId: string): Promise<AxiosResponse<StoredFileWithExistsProperty[]>> {
    const url = queryString.stringifyUrl({ url: Urls.pro.applicationFiles, query: { applicationId } });
    return this.instance.get(url);
  }

  public getApplicationFileWithSignedUrl(
    applicationId: string,
    fileId: string,
    options: { download: boolean; validFor: number } = { download: false, validFor: 30 },
  ): Promise<AxiosResponse<StoredFileWithSignedUrl>> {
    const url = queryString.stringifyUrl({
      url: `${Urls.pro.applicationFiles}/${fileId}`,
      query: { applicationId, ...options },
    });
    return this.instance.get(url);
  }

  public deleteApplicationFile(applicationId: string, fileId: string): Promise<AxiosResponse<StoredFile>> {
    const url = queryString.stringifyUrl({
      url: `${Urls.pro.applicationFiles}/${fileId}`,
      query: { applicationId },
    });
    return this.instance.delete(url);
  }

  public async getDynamicPdfData<TQuestionnaire, TAnswerPath>(
    applicationId: string,
    pdfType: DynamicPdfType,
    language: Language,
  ): Promise<AxiosResponse<DynamicPdfDataGetRequest<TQuestionnaire, TAnswerPath>>> {
    const url = queryString.stringifyUrl({
      url: `${Urls.shared.dynamicPdfData}/${applicationId}`,
      query: { pdfType, lang: language },
    });
    return this.instance.get(url);
  }

  public async fetchPaymentTransaction(
    applicationId: string,
    options: {
      withCustomer?: boolean;
      withToken?: boolean;
      withCardDetails?: boolean;
    } = {},
  ): Promise<AxiosResponse<PaymentTransaction | null>> {
    const url = queryString.stringifyUrl({
      url: `${Urls.shared.paymentTransactions}`,
      query: { applicationId, ...options },
    });
    return this.instance.get(url);
  }

  public async createPaymentTransaction(data: { applicationId: string }): Promise<AxiosResponse<PaymentTransaction>> {
    const url = Urls.shared.paymentTransactions;
    return this.instance.post(url, data);
  }

  public async patchPaymentTransaction(
    id: string,
    data: { paymentToken: string },
  ): Promise<AxiosResponse<PaymentTransaction>> {
    return this.instance.patch(`${Urls.shared.paymentTransactions}/${id}`, data);
  }

  public async createFileTemplateRule(data: {
    templateId: string;
    questionnaireVersionId: string;
    condition: Conditions;
  }): Promise<AxiosResponse<FileTemplateRule>> {
    return this.instance.post(Urls.shared.fileTemplateRules, data);
  }

  public async patchFileTemplateRule(
    fileTemplateRuleId: string,
    data: PatchFileTemplateRuleData,
  ): Promise<AxiosResponse<FileTemplateRule>> {
    return this.instance.patch(`${Urls.shared.fileTemplateRules}/${fileTemplateRuleId}`, {
      condition: data.condition,
    });
  }

  public async deleteFileTemplateRule(fileTemplateRuleId: string): Promise<AxiosResponse<void>> {
    return this.instance.delete(`${Urls.shared.fileTemplateRules}/${fileTemplateRuleId}`);
  }

  public async findFileTemplateRules(
    questionnaireVersionId: string,
    options?: { includeFileTemplate?: boolean; templateId?: string; includeFileTemplateRecipient?: boolean },
  ): Promise<AxiosResponse<ExpandableFileTemplateRule[]>> {
    const query: Record<string, any> = { questionnaireVersionId };

    if (options?.includeFileTemplate) {
      query.fileTemplate = true;
    }
    if (options?.includeFileTemplateRecipient) {
      query.fileTemplateRecipient = true;
    }

    if (options?.templateId) {
      query.templateId = options.templateId;
    }

    const url = queryString.stringifyUrl({ url: Urls.shared.fileTemplateRules, query });
    return this.instance.get(url);
  }

  public createFileTemplate(data: {
    name: string;
    docType: StoredFileDocType;
    origin: FileTemplateOrigin;
    externalUrl?: string | null;
    file?: File;
    identifier?: string | null;
    localizedName: { en: string; fr: string };
  }): Promise<AxiosResponse<FileTemplate>> {
    return this.instance.post(Urls.shared.fileTemplates, data);
  }

  public findFileTemplates(options?: {
    docType?: StoredFileDocType;
    origin?: FileTemplateOrigin;
    identifier?: string;
  }): Promise<AxiosResponse<FileTemplate[]>> {
    const query: Record<string, any> = { ...options };
    const url = queryString.stringifyUrl({ url: Urls.shared.fileTemplates, query });
    return this.instance.get(url);
  }

  public getFileTemplate(templateId: string): Promise<AxiosResponse<FileTemplate>> {
    return this.instance.get(`${Urls.shared.fileTemplates}/${templateId}`);
  }

  public patchFileTemplate(templateId: string, data: Partial<FileTemplate>): Promise<AxiosResponse<FileTemplate>> {
    return this.instance.patch(`${Urls.shared.fileTemplates}/${templateId}`, data);
  }

  public processParticipants(
    applicationId: string,
  ): Promise<AxiosResponse<Partial<Record<PdfDocumentType, Signer[]>>>> {
    return this.instance.get(`${Urls.shared.participantsProcessor}/${applicationId}`);
  }

  public getAgentLicenses(agentId: string): Promise<AxiosResponse<AgentLicenseProperties[]>> {
    return this.instance.get(`${Urls.pro.agentLicenseValidation}/${agentId}`);
  }

  public createAgentLicenses(data: { agentId: string }): Promise<AxiosResponse<AgentLicenseProperties[]>> {
    return this.instance.post(`${Urls.pro.agentLicenseValidation}`, data);
  }

  public getPointOfSaleDecisions(applicationId: string): Promise<AxiosResponse<ApplicationPointOfSaleDecisions>> {
    return this.instance.get(`${Urls.shared.pointOfSaleDecision}/${applicationId}`);
  }

  public async getApplicationSupportApplications(
    options: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters>,
  ): Promise<AxiosResponse<Paginated<ApplicationSupportApplication>>> {
    const queryOptions = options ? buildPageQueryOptions(options) : {};

    const queryString = convertObjectToQueryString<ApplicationSupportApplication>(queryOptions);

    return this.instance.get(`${Urls.admin.applicationSupportApplications}${queryString}`);
  }

  public async getApplicationSupportPageApplication(
    applicationId: string,
  ): Promise<AxiosResponse<ApplicationSupportApplicationOverview>> {
    return this.instance.get(`${Urls.admin.applicationSupportApplications}/${applicationId}`);
  }

  public async resetApplicationSupportApplicationSubmissionStatus(data: {
    applicationId: string;
  }): Promise<AxiosResponse<ApplicationSupportApplication>> {
    return this.instance.post(Urls.admin.applicationSupportApplicationsSubmissionReset, data);
  }

  public async getApplicationSupportApplicationSubmittedFiles(applicationId: string): Promise<Buffer> {
    const response = await this.instance.get(
      `${Urls.admin.applicationSupportApplicationsSubmissionDownloadFiles}/${applicationId}`,
      { responseType: 'arraybuffer' },
    );

    return Buffer.from(response.data);
  }

  public createDataExtractRequest(data: { type: string }): Promise<AxiosResponse<{ success: boolean }>> {
    return this.instance.post<{ success: boolean }>(Urls.pro.dataExtract, data);
  }

  public async getProductsWidgetTotalPremiums(applicationId: string): Promise<AxiosResponse<TotalPremiums | null>> {
    const url = queryString.stringifyUrl({ url: Urls.shared.totalPremiums, query: { appId: applicationId } });
    return this.instance.get(url);
  }

  public async getComprehensivePricing(
    applicationId: string,
    answers: Answers,
    answersV2: Answers,
    lineOfBusiness: LineOfBusinessName,
  ): Promise<ComprehensivePricingAPIReturnValue> {
    const response = await this.instance.put(`${Urls.shared.comprehensivePricing}/${applicationId}`, {
      answers,
      answersV2,
      lineOfBusiness,
    });
    return response.data;
  }

  public async getUnderwritingReportsOutcomes(
    addplicationId: string,
  ): Promise<AxiosResponse<InsuredUnderwritingOutcomesReports | null>> {
    const url = queryString.stringifyUrl({
      url: Urls.admin.applicationSupportUnderwritingReports,
      query: { applicationId: addplicationId },
    });
    return this.instance.get(url);
  }

  public getJetDecisionOutcomes(applicationId: string): Promise<AxiosResponse<JetDecisionOutcomes[]>> {
    return this.instance.get(`${Urls.pro.jetWidgetOutcomes}/?appId=${applicationId}`);
  }

  public async getApplicationSupportESignatureCeremonies(
    applicationId: string,
    pageQueryOptions?: PageQueryOptions<ApplicationSupportESignatureCeremony>,
  ): Promise<AxiosResponse<ApplicationSupportESignatureCeremony[]>> {
    const defaultSortParams: SortParams<ApplicationSupportESignatureCeremony> = {
      field: 'createdAt',
      direction: 'desc',
    };

    const queryOptions = pageQueryOptions ? buildPageQueryOptions(pageQueryOptions) : { $sort: defaultSortParams };

    const queryParameters = convertObjectToQueryString<ApplicationSupportESignatureCeremony>({
      ...queryOptions,
      applicationId,
    });

    return this.instance.get(`${Urls.admin.applicationSupportESignaturesCeremonies}${queryParameters}`);
  }

  public async getApplicationSupportAssociatedFiles(
    applicationId: string,
    pageQueryOptions?: PageQueryOptions<ApplicationSupportAssociatedFile>,
  ): Promise<AxiosResponse<Paginated<ApplicationSupportAssociatedFile>>> {
    const queryOptions = pageQueryOptions ? buildPageQueryOptions(pageQueryOptions) : { $page: 1, $limit: 50 };

    const queryParameters = convertObjectToQueryString<ApplicationSupportAssociatedFile>({
      ...queryOptions,
      applicationId,
    });

    return this.instance.get(`${Urls.admin.applicationSupportAssociatedFiles}${queryParameters}`);
  }

  public async getUserQuestionnaires(
    options?: FindQuestionnairesForProUserQuery,
  ): Promise<AxiosResponse<Questionnaire[]>> {
    const queryParams: UrlObject = { url: Urls.pro.questionnaires, query: {} };
    if (options?.questionnaireVersionId) {
      queryParams.query.questionnaireVersionId = options.questionnaireVersionId;
    }
    const url = queryString.stringifyUrl(queryParams);
    return this.instance.get(url);
  }

  public async updateNotification(id: string, data: Partial<Notification>): Promise<AxiosResponse<Notification>> {
    return this.instance.patch<Notification>(`${Urls.pro.notifications}/${id}`, data);
  }

  public async updateAllNotifications(data: Partial<Notification>): Promise<AxiosResponse<Notification[]>> {
    return this.instance.patch<Notification[]>(`${Urls.pro.notifications}`, data);
  }

  public async fetchAllNotifications(cursorDate: Date | undefined): Promise<AxiosResponse<Notification[]>> {
    const url = queryString.stringifyUrl({
      url: Urls.pro.notifications,
      query: { cursorDate: cursorDate?.toString() },
    });
    return this.instance.get<Notification[]>(url);
  }

  public async getPricingFieldIdentifiers(options?: {
    questionnaireVersionId?: string | null;
  }): Promise<QuestionnaireVersionPricingNodeIds[]> {
    const queryParams: UrlObject = { url: Urls.shared.pricingFieldIdentifiers, query: {} };
    if (options?.questionnaireVersionId) {
      queryParams.query.questionnaireVersionId = options.questionnaireVersionId;
    }
    const url = queryString.stringifyUrl(queryParams);
    return (await this.instance.get(url)).data;
  }

  public async patchPricingFieldIdentifiers(
    pricingFieldIdentifiersId: string,
    data: Pick<QuestionnaireVersionPricingNodeIds, 'nodeIds'>,
  ): Promise<AxiosResponse<QuestionnaireVersionPricingNodeIds>> {
    return this.instance.patch(`${Urls.shared.pricingFieldIdentifiers}/${pricingFieldIdentifiersId}`, data);
  }

  public async getProductsEntity(data: any): Promise<AxiosResponse<ProductsEntity<string>>> {
    return this.instance.post<ProductsEntity<string>>(`${Urls.shared.productsEntity}`, data);
  }

  public async findUsedLeadStatuses(
    archived: boolean,
    statusType: 'status' | 'signatureStatus',
  ): Promise<AxiosResponse<string[]>> {
    const url = queryString.stringifyUrl({ url: Urls.pro.leadStatuses, query: { archived, statusType } });
    return this.instance.get<string[]>(url);
  }
}
